/** 
 * 数字人
 */
<template>
  <v-card class="vrLiveBroadcast">
    <v-banner
      single-line
      height="500"
      class="banner"
    >
          <v-img
            src="https://h5.ophyer.cn/official_website/banner/dig-banner-01.png"
            height="100%"
            class="d-flex align-center"
            >
            <v-container color="transparent" class="px-6 max-width-1200 height-per-100 d-flex align-center">
              <div class="flex-grow-1">
                <v-card color="transparent" class="ml-0" flat>
                  <v-card-title class="pa-0 font-size-banner-title family-Bold">
                    <div>
                      <div class="pt-2 color-FFF" style="font-weight: 500; ">数字人</div>
                    </div>
                  </v-card-title>
                  <v-card-title class="pa-0 font-size-banner-tip mt-8 color-FFF">
                    AI与生产力结合的新物种
                  </v-card-title>
                </v-card>
              </div>
              <div class="flex-grow-1">
                <v-img src="https://h5.ophyer.cn/official_website/banner/dig-banner-02.png"></v-img>
              </div>
            </v-container>
          </v-img>
    </v-banner>
    <!-- 2.核心价值 -->
    <div class="d-flex justify-center pb-15">
      <v-card class="warp" flat >
        <div class="public-title" style="padding-bottom:28px;">
          <p>CORE VALUE</p>
          <div>
            <span>核心价值</span>
          </div>
        </div>
        <v-container class="first d-flex justify-center">
          <div class="cards mx-4" v-for="item in data.wrap2.list" :key="item.id">
            <v-card class="px-4" flat >
              <v-card-title class="tit  mt-7 mb-5 pa-0" v-text="item.title"></v-card-title>
              <v-card-text class="tip" v-text="item.content" v-html="item.content" />
            </v-card>
          </div>
        </v-container>
      </v-card>
    </div>
    <!-- 3.方案构成 -->
    <v-card class="warps warp5 pt-2 pb-5" flat color="#FBFBFB">
      <div class="public-title">
        <p>SCHEME COMPOSITION</p>
        <div>
          <span>方案构成</span>
        </div>
      </div>
      <div class="model_list3">
        <v-row no-gutters justify='center'>
          <v-col class="px-4 mb-10"  v-for="(v, k) in data.warp1.charList" :key="k" cols="3">
            <div class="model_item">
              <img class="item_img" :src='v.src' />
              <div class="item_info">
                <v-card-title class="justify-center reset-title">{{ v.title }}</v-card-title>
                <v-card-text class="reset-text">{{ v.content }}</v-card-text>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- 4.使用场景 -->
    <v-card class="justify-center warp3" :flat="true" style="margin-bottom: 38px;padding: 10px 0;">
      <div class="public-title">
        <p>APPLICATION SCENARIO</p>
        <div>
          <span>应用场景</span>
        </div>
      </div>
      <v-container class="max-width-1200 mt-11">
        <v-row  justify='space-between' >  
          <v-col cols="4"  sm="4" v-for="n in data.warp3.sceneList" :key="n.id">
            <v-card flat class="advantage_card" >
              <div class="img_title" justify="center">
                <v-img :src="n.src" class="align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)">
                </v-img>
                <v-card-text class="pa-0 mt-1 ml-1 text-align-center">
                   <span class="font-size-16  line-height-17">{{n.title}}</span>
                </v-card-text>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <!-- <v-card class="warp warp4" :flat="true" width="1200">
      <div class="public-title" style="margin-bottom:40px;margin-top:70px">
        <p>SCENES TO BE USED</p>
        <div>
          <span>使用场景</span>
        </div>
      </div>
      <div class="con mb-15">
        <v-row>
          <v-col
            v-for="(n,i) in data.warp3.sceneList" :key="i" cols="4"
            class="d-flex child-flex"
          >
            <div class="box-img" :style="{backgroundImage: 'url('+n.src+')'}">
              <div>
                {{ n.title }}
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card> -->
  
  </v-card>
</template>

<script>
import contentData from '@/static/contentData.js'

let _this;
export default {
  name: "digitalMan",
  data(){
    return{
      data: contentData.digitalMan,
      detailIndex: 1,
      bannerLoad: false,
      imgLoad: false,
      tab:null,
      vtab: null,
    
    }
  },
  mounted(){
    _this = this;
    this.bannerLoad = true;
  },
  methods: {
    handleLoad: ()=>{
      _this.imgLoad = true;
      console.log("ewf")
    }
  }
}
</script>

<style lang="scss" scoped>
.first {
  display: flex;
  .cards{
    width: 300px;
    height: 150px;
    position: relative;
    &:nth-child(1){
      background: #FFFFFF;
      box-shadow: 0px 10px 26px 0px rgba(97, 162, 255, 0.2);
      border-radius: 16px;
      &::before{
        content: "";
        width: 60px;
        height: 4px;
        background: #2263DF;
        position: absolute;
        top: 53px;
        z-index: 99;
        left: 18px;
      }
    }
    &:nth-child(2){
      background: #FFFFFF;
      box-shadow: 0px 10px 26px 0px rgba(237, 131, 120, 0.2);
      border-radius: 16px;
      &::before{
        content: "";
        width: 60px;
        height: 4px;
        background: #ED8378;
        position: absolute;
        top: 53px;
        z-index: 99;
        left: 18px;
      }
    }
    &:nth-child(3){
      background: #FFFFFF;
      box-shadow: 0px 10px 26px 0px rgba(239, 201, 83, 0.2);
      border-radius: 16px;
      &::before{
        content: "";
        width: 60px;
        height: 4px;
        background: #EFC953;
        position: absolute;
        top: 53px;
        z-index: 99;
        left: 18px;
      }
    }
  }

}
.public-title{
  height: unset;
  margin-bottom: 0px;
}
.warps {
  margin: 0 auto;
  text-align: center;
}
.warp {
  width: 1200px;
  margin: 0 auto;
  img{
    border-radius: 15px;
  }
}
.warp4 {
  .con {
    margin-top: 45px;
    .box-img {
      width: 240px;
      height: 160px;
      border-radius: 10px;
      background-size: cover;
      overflow: hidden;
      transition: all 0.3s ease-in;
      div {
        width: 100%;
        height: 100%;
        background-color:rgba(25, 26, 27, 0.5);
        color: #fff;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
::v-deep .advantage_card{
  transition: 0.3s;
  width: 300px;
  height: 206px;
  margin: 0 auto 60px;
  &:hover {
      transform: scale(1.04);
      box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
      background: #FBFBFB !important
    }
}
::v-deep .warp3{
  .max-width-1200{
    width: 1080px;
  }
  .theme--light.v-card{
    // background: #FBFBFB !important
  }
  .img_title {
      // width: 266px;
      margin: 0 auto;
      text-align: center;
      .theme--light.v-image {
        margin: 0px auto 30px;
        border-radius: 10px;
        // width:400px ;
        // height: 260px;
        object-fit: cover;
      }
      h2 {
        margin-top: 10px;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }
      p {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        margin-top: 5px;
        padding: 0px 45px
      }
  }
}
.warp5 {
  width: 100%;
  .model_list3 {
    width: 1200px;
    margin: 38px auto 0 auto;
    position: relative;
    .model_item{
      width: 260px;
      height: 220px;
      background: #FFFFFF;
      border-radius: 16px;
      padding: 10px 14px;
      .item_img{
        width: 100px;
        height: 100px;
        object-fit: contain;
      }
      .item_info{
        margin-top: 10px;
      }
    }
  }
}
</style>